<!--
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-14 10:59:14
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-12-01 10:33:10
 * @Description: 
 * @FilePath: /ezpsy/src/pages/index/IndexPage.vue
-->

<template>

    <el-container>
    <el-header>
        <NavibarPage />

    </el-header>
    <el-main>
      <el-skeleton  :loading="loading" style="width: 100%" animated>
        <template  #template>
          <el-skeleton-item variant="image" style="width: 100%;height: 75vh; margin-top: 0vh;" />
        </template>
        <template #default>
        <el-carousel height="75vh" arrow="always" style="margin-top: 0vh;">
      <el-carousel-item v-for="item in banner_img" :key="item" class="bannerImg">
        <img :src="item" alt="" style="width: 100%;height: 75vh;">
      </el-carousel-item>
    </el-carousel>
  </template>
  </el-skeleton>
      <div class="product" >     
       <p class="title1 wow animate__fadeInUp" > —— &nbsp;&nbsp; 我们的产品</p>
       <h2 class="title2 wow animate__fadeInUp" >脑科学实验在线设计平台</h2>  
       <el-card class="blockImg wow animate__fadeInUp" >
        <img src="@/assets/img/block.png" style="float:left;width: 30vw; margin-left: 5vw; padding-bottom: 5vh;">
        <div class="five_button">
          <el-button class="button1">可视化编程</el-button>
          <el-button class="button1">实验在线存储</el-button>
          <el-button class="button1">高精度时序精度</el-button>
          <el-button class="button1">数据在线收集</el-button>
         <a href="#"><el-button class="button2" @click="toConsole()">了解更多</el-button></a> 
        </div>
       </el-card>
 
   
    <h2 class="title2 wow animate__fadeInUp" >高精度配套设备</h2>
    <div class="machine">
      
      <el-card class="m_card wow animate__fadeInUp" shadow="hover"   v-for="item,index in all_machine" :key="item" :style="index==0?' margin-left: 0vw;':''">
        <img :src="item.url" style="width: 100%;">
        <h3>{{item.name}}</h3>
        <p>{{item.content}}</p>
      </el-card>
    </div>
      </div>


      <div class="core">
       
        <p class="title1 wow animate__fadeInUp"  style="text-align: center; margin: 0; padding-top: 5vh;">EZPSY核心技术——华为Serverless赋能</p>

        <div class="machine" style="margin-top:5vh">
          
         
      <el-card class="m_card wow animate__fadeInUp" shadow="never"  v-for="item,index in core_tech" :key="index" :style="index==0?' margin-left: 0vw;':''">
        <!-- <img :src="item.url" alt="" style="width: 100%; height: 10vh;"> -->
        <el-image :src="item.url" style="width:100%;height:10vh" fit="contain"></el-image>
        <h3 >{{item.name}}</h3>
        <p style="font-weight:400; font-size: 1.1vw;width: 80%;margin-left: 10%;text-align: left;line-height: 1.2;">{{item.content}}</p>
        
        <el-button class="button2" style="font-size: 1.1vw;
        margin: 0;width: 7vw; height: 4vh; margin-bottom: 2vh;"
        @click="openMore(index,item.more)"
        >更多内容</el-button>
     
      </el-card>
        
    </div>
      </div>

      <div class="compare">
        <div class="c_left">
          
          <img  src="@/assets/img/compare.png" alt="" :key="1" style="width: 100%;" class="wow animate__fadeInUp">
          <h3 class="wow animate__fadeInUp" :key="2">可服务国内61所研究机构、112所大学和400个相关实验室</h3>
        
        </div>
        <div class="c_right">
        
        <p  class="title1 wow animate__fadeInUp" style="margin: 0;"  :key="1">—— 竞品分析</p>
       <h2 class="title2 wow animate__fadeInUp" style="margin: 0;" :key="2">为什么选择我们？</h2>
       <p class="grey_p wow animate__fadeInUp" :key="3">与国外软件相比具有明显优势</p>
         
       <div class="comment">

        <h1 class="wow animate__fadeInUp" style=
          "font-family: '宋体'; position: absolute;font-size: 4vw;color: #3a57e8;text-align: left;margin:  2vh 2vw;margin-bottom: 0;">“</h1>
       <p class="wow animate__fadeInUp">EzPsy团队开发的软件是唯一可以满足视觉实验时序精度要求的在线软件，希望该团队继续优化该产品，以适应更多实验场景。<br><br>
       —— 北京大学心理与认知科学学院研究员 鲍平磊</p>
   
      </div>
     
      <el-button class="button2 wow animate__fadeInUp" 
      style="margin: 0;width: 10vw; height: 5vh; margin-top: 3vh;"
      @click="openImDi()"
      >了解更多</el-button>
        </div>
      </div>
    <div style="background-color: aliceblue;  display: inline-block">
      <div class="success">
        
        <p class="title1 wow animate__fadeInUp" style="margin: 0;">—— 最近工作</p>
       <h2 class="title2 wow animate__fadeInUp" style="margin: 0;" >成功案例</h2>
        <div class="s_picture">
        
          <div class="sp_left" >
            <div  class="flagText wow animate__fadeInUp">
              <h2 style="margin-bottom: 0;" >团队工作</h2>
              <p style="margin-top: 1vh;" >在华为公司深度交流</p>
            </div>
            <img src="@/assets/img/team.jpg" alt="" class="wow animate__fadeInUp" style="box-shadow: 0 0 0 1px #d7d7d7; height: 60vh;">
          </div>
          <div class="sp_right" >
            <div  class="flagText ">
              <h2 style="margin-bottom: 0;" class="wow animate__fadeInUp">地址</h2>
              <p style="margin-top: 1vh;" class="wow animate__fadeInUp">安徽医科大学东校区</p>
            </div>
            <div  class="flagText wow animate__fadeInUp" style="margin-top: 15vh;">
              <h2 style="margin-bottom: 0;">理念</h2>
              <p style="margin-top: 1vh;">为了掰开那只手，我们一直在努力！</p>
            </div>
            <img src="@/assets/img/dream.png" class="wow animate__fadeInUp" alt="" style="height: 29vh;">
            <img src="@/assets/img/map.png" alt="" class="wow animate__fadeInUp" style="height: 29vh; margin-top: 1.5vh;">
          
          </div>
        </div>
      </div>
 
      <div class="opinion" >
     
          <div class="viewpoint wow animate__fadeInUp" v-for="item,index in team_text" :key="item" :style="index==0?'margin-left:0':''">
            <h2>{{item.title}}</h2>
            <p>{{item.content}}</p>
          </div>
        </div>
      </div>
        <div class="blog">
         
         <a href="/about.html"> <el-button class="button2 explore wow animate__fadeInUp"  :key="1">扩展更多</el-button></a>
          <p class="title1 wow animate__fadeInUp" style="margin: 0; " :key="2">—— 来源于我们的博客</p>
       <h2 class="title2 wow animate__fadeInUp" style="margin: 0;"  :key="3">最新新闻</h2>
       
       <div class="machine" style="margin:0">
       
      <div class="new_card wow animate__fadeInUp" @click="lookNews(item)" shadow="hover" v-for="item,index in fileList" :key="item" :style="index==0?' margin-left: 0vw;':''">
        <img :src="item.banner" alt="" style="height: 28vh;">
        <div class="animate_h"><a href="#">{{item.title}}</a></div>
      </div>
    </div>
        </div>
      <!-- <el-button @click="tiao">login</el-button>
      <el-button @click="tiao2">cloudDB</el-button>
      <el-button @click="tiao3">cloudStorage</el-button>
      <iframe src="./static/program-block.html" frameborder="0"  style="width:1400px;height:800px;margin:0 auto"></iframe> -->
    </el-main>
    <el-footer>
     <FooterPage/>
    </el-footer>
       
  </el-container>
</template>
<script>
import * as Auth from '@/assets/js/auth';
import { setStorage } from "@/assets/js/storage";
import * as cloudStorage from '@/assets/js/cloudStorage/cloudStorage'
import NavibarPage from '@/components/NavibarPage.vue';
import FooterPage from '@/components/FooterPage.vue';
// import { ElButton, ElContainer,ElHeader,ElFooter,ElMain,ElCard,ElCarousel,ElCarouselItem,ElDivider,ElSkeleton,ElSkeletonItem } from 'element-plus';
import * as web_contentDB from "@/assets/js/cloudDatabase/web_content";


export default {
    name: "IndexPage",
    data(){
        return{
        banner_img:[],
        fileList:[],
        isLogin: false,
       img2:'',
        // all_machine: [
        // {url:'https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Findex%2Frecord.png?token=f839a560-4b9a-472e-aee5-f245f8c0d85e',name:'反应时记录仪（二代）',content:'记录反应的时差\n操作更易\n通信更稳\n精度：0.01ms'},
        // {url:'https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Findex%2Fsync.png?token=54709369-1d0d-4dd7-a2d7-4c9e03296ad7',name:'在线脑科学实验同步仪',content:'准确标定刺激出现的时刻\n适用更广\n国内首创在线配套设备\n精度：0.05ms'},
        // {url:'https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Findex%2Fcheck.png?token=eb1522ff-4f43-4d32-817f-45c6892d895c',name:'高精度视频刺激校准仪',content:'检测VGA视频信号\n功能更全\n测量更准\n国内首创在线配套设施'}],
        // core_tech:[{url:'https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Findex%2Fezpsy_js.png?token=7400a9d3-500d-4ac1-99a9-af505987bf99',name:'Ezpsy.js',content:'自研函数库，打破技术壁垒'},
        // {url:'https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Findex%2Ftiming_boosting.png?token=9e77a4ae-de94-4e74-85e6-7a779c69e4af',name:'亚毫秒时序精度',content:'自研Timing-Boosting技术，呈现最优精准刺激'},
        // {url:'https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Findex%2Fall_mechine.png?token=60c99ad5-2884-479a-9dad-bc4848c21c9d',name:'高精度实验设备',content:'反应时记录仪，同步仪，校准仪（使用国产芯片）'},
        // {url:'https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Findex%2Fview_program.png?token=744bff24-ad7c-450c-a085-0746c28e9b50',name:'在线可视化编程',content:'低代码编程，拖拽即可完成实验设计'}],
        // team_text:[{title:'团队愿景',content:'团队成员多学科交叉，在我们共同的努力下，打破原有的脑科学实验设计的生态，研发出不受他人限制的一套脑科学实验设计系统。'},
        // {title:'项目实现',content:'行业权威人士对我们目前已经开发完成的平台及高精度硬件采集设备做出了高度评价，得到本领域专家高度认可并在公司、高校实验室实际落地。'},
        // {title:'相关合作',content:'走进华为公司和命题团队及Serverless技术专家深入交流。技术、命题实现、商业价值及社会价值均得到华为技术专家高度认可，生态部部长刘连喜力荐！'}],
        // foot_text:[{url:'https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Findex%2Fphone.png?token=139e04d4-a2a7-4616-a300-aa717fbb91d8',name:'电话',content:'(+086) 15056709773'},
        // {url:'https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Findex%2Femail.png?token=95fbce4e-323f-4297-b887-87cf1da7eb88',name:'邮箱',content:'service@ezpsy.net'},
        // {url:'https://agc-storage-drcn.platform.dbankcloud.cn/v0/ezpsy-qfqih/publicFile%2Findex%2Faddress.png?token=15a8ff4e-c03d-4c97-96ca-d296efc3bfda',name:'地址',content:'安徽省合肥市瑶海区'},]
        all_machine: [
        {url:require('@/assets/img/record.png'),name:'反应时记录仪',content:'记录反应的时差\n操作更易\n通信更稳\n精度：0.01ms'},
        {url:require('@/assets/img/sync.png'),name:'在线脑科学实验同步仪',content:'准确标定刺激出现的时刻\n适用更广\n国内首创在线配套设备\n精度：0.05ms'},
        {url:require('@/assets/img/check.png'),name:'高精度视频刺激校准仪',content:'检测VGA视频信号\n功能更全\n测量更准\n国内首创在线配套设施'}],
        core_tech:[
          {url:require('@/assets/img/ezpsy_js.png'),name:'Ezpsy.js',content:'自研函数库，打破技术壁垒',more:'https://github.com/ATSLotus/ezpsy'},
        {url:require('@/assets/img/timing_boosting.png'),name:'亚毫秒时序精度',content:'自研Timing-Boosting技术，呈现最优精准刺激',more:''},
        {url:require('@/assets/img/all_mechine.png'),name:'高精度实验设备',content:'反应时记录仪，同步仪，校准仪（使用国产芯片）',more:''},
        {url:require('@/assets/img/view_program.png'),name:'在线可视化编程',content:'低代码编程，拖拽即可完成实验设计',more:''}],
        team_text:[{title:'团队愿景',content:'团队成员多学科交叉，在我们共同的努力下，打破原有的脑科学实验设计的生态，研发出不受他人限制的一套脑科学实验设计系统。'},
        {title:'项目实现',content:'行业权威人士对我们目前已经开发完成的平台及高精度硬件采集设备做出了高度评价，得到本领域专家高度认可并在公司、高校实验室实际落地。'},
        {title:'相关合作',content:'走进华为公司和命题团队及Serverless技术专家深入交流。技术、命题实现、商业价值及社会价值均得到华为技术专家高度认可，生态部部长刘连喜力荐！'}],
       
        
  }
    },
    async created() {
      
        await setStorage(); //设置端侧数据
        // await cloudDatabase.init();
        await Auth.judgeIsLogin(); //判断是否登录，如果未登录则匿名登录
        await cloudStorage.cloudStorageInit();
        this.banner_img =  await cloudStorage.getPublicAllImg("index/banner")
        this.fileList = await web_contentDB.executeQueryNewArticle('issueDate',3)
        await Auth.getUserInfo().then(async e => {
            if(e.res){
                this.isLogin = !e.user.isAnonymous();
            }
        })
      // await cloudDatabase.close()
      // Swal.fire({
      //       title: '访问中',
      //       text: '正在访问服务器,请稍等...',
      //       allowOutsideClick: false,
      //       didOpen: ()=>{
      //           Swal.showLoading();
      //       }
      //   })
      //   let interval = setInterval(()=>{
      //       if(cloudDatabase.isSuccess  && cloudStorage.isSuccess)
      //       {
              
      //         clearInterval(interval);
      //           Swal.close()
      //       }
      //   },500)
    
    },
    components: { NavibarPage,FooterPage}
    ,
    mounted(){
 
      

     
    // this.img2=this.all_img.get('map')
    // window.onbeforeunload = function (e) {
    //   const storage = window.localStorage
    //   storage.clear()
    // }
     
        // window.addEventListener('scroll', this.scrollDs)
    },
    computed:{
      loading(){
        if(this.banner_img.length==0)
        return true
        else return false
      }
      // all_img(){
      //   return  cloudStorage.getPublicAllImg("index")
      // }
    },
    
    methods:{
      lookNews(item){
        localStorage.setItem('new_item',JSON.stringify(item))
        location.href='/news.html'
      },
     async openMore(index,date){
        switch(index){
        case 0:window.open(date);break;
        case 1:{
          let new_item=await web_contentDB.getArticleListById('348owaf4pj');
          localStorage.setItem('new_item',JSON.stringify(new_item[0]))
          location.href='/news.html'
        }break;
        case 2:{
          let new_item=await web_contentDB.getArticleListById('15cmju2788');
          localStorage.setItem('new_item',JSON.stringify(new_item[0]))
          location.href='/news.html'}break;
        case 3:this.toConsole();break;
        }
      },
      async openImDi(){
      let new_item=await web_contentDB.getArticleListById('15cmjxlrp9');
          localStorage.setItem('new_item',JSON.stringify(new_item[0]))
          location.href='/news.html'
      },
      toConsole(){
        if(this.isLogin)
          location.href = "/ezpsy.html"
        else
          location.href = "/login.html"
      }
    },
    

}

</script>
<style lang="scss">
    @import "element-plus/dist/index.css";

    $blueColor:#3a57e8;
    #index{
      // color: #42b983;
      color: #BBE2F0;
    }
    body{
  padding: 0;
  margin: 0;
}
    .el-header{
  padding: 0;
}
img{
  width:100%
}
a{
  text-decoration: none;
}
.el-main{
  padding: 0;
  overflow-y: hidden;
}
.bannerImg{
    width: 100%;
    height: 75vh;
}
.topTar{
    background-color: black;
    color: white;
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    text-indent: 2vw;
    font-size: 0.9vw;
}
.icon1{
display: flex;
height: 10vh;
width: 100%;
/* background-color: #3a57e8; */
}
.icon1 h2{
    font-size: 1.2vw;
    margin-top: 3vh;
    padding: 0px;
    margin-bottom: 0;
}

.product{
  width: 100%;
  margin-top: 8vh;
  // height: 158vh;
  margin-bottom: 5vh;
  // background-color: #3a57e8;
}

.title1{
  color: #3a57e8;
  font-size: 1.4vw;
  margin: 0;
  margin-left: 10vw;
  text-align: left;
}
.title2{
  font-size: 2.5vw;
  margin: 1.5vh;
  margin-left: 10vw;
  text-align: left;
  padding: 1vh 0;
}
.five_button{
  width: 15vw;
  float: right;
  margin-right: 10vw;
  text-align: center;
  margin-top: 5vh;
}
.blockImg{
   width: 80vw;
   margin-left: 10vw;
  //  color: black;
  background-color: #BBE2F0;
   /* height: 60vh; */
}

.button1{
  width: 15vw;

  height: 7vh;
  margin-top: 5vh;
  border-color: #3a57e8;
  border-width: 0.3vh;
  color: #3a57e8;
  border-radius: 0;
  font-size: 1.3vw;
  margin-left: 0;
}
.el-button+.el-button{
    margin-left: 0;
}
.button1:hover{
  color: #fcb900;
  border-color: #fcb900;
  background-color: white;

}

.button2{
  width: 13vw;
  margin: 0;
  height: 7vh;
  background-color: #3a57e8;
  color: white;
  border-radius: 0;
  margin-top: 5vh;
  font-size: 1.3vw;
}

.start{
    margin-left: 74vw;
    font-size: 0.8vw;
    width: 7vw;
    height: 5vh;
    margin-top: 3vh;
    background-color: #3a57e8;
    color: white;
}
.start:hover{
    background-color: #fcb900;
}
.machine{
 display: flex;
 width: 80vw;
 margin-left: 10vw;
}
.m_card{
  width: 25vw;
  margin-left: 2.5vw;
  display: block;
  background-color: aliceblue;
}
.m_card h3{
  font-size: 1.5vw;
}
.m_card p{
  font-size: 1.2vw;
  color: #717171;
  line-height: 3.5vh;
  font-weight: 700;
  white-space: pre-line
}
.core{
  background-color: #f8f8f8;
  height: 60vh;
  width: 100%;
}
.compare{
  height: 70vh;
  width: 90%;
  margin-left: 10%;
  // background-color: aliceblue;
}
.flagText{
  color: aliceblue;
  margin-top: 45vh;
  margin-left: 3vw;
  font-size: 1.2vw;
  text-align: left;
  position:absolute
}
.c_left{
  float: left;
  width: 35vw;
  text-align: left;
  font-size: 1.3vw;
  margin-top: 7vh;
}
.c_right{
  float: left;
  width: 45vw;
  text-align: left;
  margin-left: 5vw;
  margin-top: 10vh;
}
.comment{
 
  width: 36vw;
  background-color: #f9faff;
  height: 23vh;
  border-radius: 3vh;
  box-shadow: -0.5vh 0px 0px 0px #3a57e8;
}
.comment p{
  padding-top: 7vh;
  margin:  5vh 2vw;
  font-style: italic;
  font-weight: 500;
  font-size: 1.1vw;
}
.grey_p{
  color: #717171;
  font-size: 1.1vw;
  font-weight: 700;
}

.success{
  width: 80%;
  margin-left: 10%;
  margin-top: 5vh;
  display: block;
  height: 77vh;
  // background-color: #f8f8f8;
}
// .s_picture{
  
// }

.sp_left{
  width: 45vw;
  float: left;
}
.sp_right{
  width: 30vw;
  float: left;
  margin-left: 2vw;
}

.opinion{
  height: 30vh;
  display: flex;
  width: 80%;
  margin-left: 10%;
}
.viewpoint{
  display: block;
  text-align: left;
  margin-left: 7vw;
}
.viewpoint h2{
  font-size: 1.4vw;
}
.viewpoint p{
  font-size: 1.2vw;
}

.blog{
  width: 80vw;
  margin-left: 10vw;
  // height: 60vh;
  margin-bottom: 5vh;
  margin-top: 5vh;
}

.explore{
  // position: absolute;
  margin: 0;
  float: right;
  margin-top: 4vh;
  width: 8vw;
  height: 5vh;
  font-size: 1vw;
}
.new_card{
  width: 33%;
  margin-left: 2.5vw;
  padding: 0%;
 box-shadow: 0 0 1px 0px #717171;
 cursor: pointer;
}
.animate_h {
    width: 90%;
    height: 10vh;
    line-height: 4vh;
    font-size: 1.4vw;
    text-align: center;
    // line-height: 60px;
    color: black;
    font-weight: bold;
    margin-left: 5%;
  margin-top: 1vh;
    a{
    
    display: inline;
    text-decoration: none;
    background: linear-gradient(to right, currentColor 0%, currentColor 100%);
    background-size: 0 2px;
    background-position: 0 95%;
    transition: background-size 0.25s linear 0s;
    background-repeat: no-repeat;
    color: black;
    }
  a:hover{
    background-size: 100% 2px;
    outline: 0;
    color: $blueColor;
    
  }
    }
    
.blog h3{
  padding: 1.5vh 2vw;
  font-size: 1.3vw;
  text-align: left;
}

.el-footer{

  padding: 0;
  margin: 0;
}


</style>