/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-04 14:50:32
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-10-03 14:18:04
 * @Description: 
 * @FilePath: /ezpsy/src/assets/js/cloudDatabase/web_content.js
 */

import { web_content } from "@/components/model/web_content";
import * as cDB from "@/assets/js/cloudDatabase/cloudDatabase";    

/**
 * @description: 增加新角色
 * @Date: 2022-09-06 08:53:25
 * @param {*} Article
 * @return {*}
 */
export async function addNewArticle(Article) {
    return await cDB.addNewData(Article);
}

/**
 * @description: 通过id获取角色列表
 * @Date: 2022-09-12 15:17:54
 * @param {*} id
 * @return {*}
 */
export async function getArticleListById(id){
    const query = await cDB.getQuery(web_content,'id',id);
    return await cDB.executeQuery(query);
}

export async function executeQueryAllArticle(){
    return await cDB.executeQueryAll(web_content);
}

export async function executeQueryNewArticle(obj,num){
    const query=await cDB.getQueryNews(web_content,obj,num)
    return await cDB.executeQuery(query);
}

/**
 * @description: 通过id删除角色id
 * @Date: 2022-09-12 15:23:55
 * @param {*} id
 * @param {*} roleId
 * @return {*}
 */
export async function deleteArticleById(id){
    // const query = await cDB.getQuery(web_content,'id',id);
    // let ArticleList = await cDB.executeQuery(query);
    // let res = '';
    // for(let i = 0;i < ArticleList.length;i++){
    //     res = await cDB.executeDelete(ArticleList[i]);
    // }
    let Article = new web_content();
    Article.id = id;
    let res = await cDB.executeDelete(Article);
    
    return res;
}