/*
 * @Author: ATSLotus/时桐
 * @Date: 2022-09-14 10:59:20
 * @LastEditors: ATSLotus/时桐
 * @LastEditTime: 2022-09-14 11:01:47
 * @Description: 
 * @FilePath: /ezpsy/src/pages/index/main.js
 */

import { createApp } from 'vue';
import IndexPage from './IndexPage.vue';
import  ElementPlus  from "element-plus";
import locale from 'element-plus/lib/locale/lang/zh-cn';


createApp(IndexPage).use(ElementPlus,{locale}).mount("#app");